import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import { Spinner, Table } from "react-bootstrap";
import { useIsMounted } from "../../../hooks/useIsMounted";
import orderRequests from "../../../request/order";

export const OrderList = () => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState([]);
  const isMounted = useIsMounted();

  // console.log(order, "order");

  useEffect(() => {
    orderRequests
      .getorder()
      .then((orders) => {
        if (isMounted.current) setOrder(orders);
        // console.log(orders);
      })
      .finally(() => {
        if (isMounted.current) setLoading(false);
      });
  }, [isMounted]);

  function formatDate(date) {
    const mdate = new Date(date);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return mdate.toLocaleDateString("en-US", options);
  }

  return (
    <>
      <Helmet title="Principal Order" />

      <div className="d-flex justify-content-between">
        <SimplePageTitle>Order List</SimplePageTitle>
      </div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Items Name</th>
                <th>Total Amount</th>
                <th>Payment Status</th>
                <th>Payment Method</th>
                <th>Shipping Address</th>
                <th>Order Status</th>
                <th>Order Date</th>
              </tr>
            </thead>
            <tbody>
              {order.map((orderData, idx) => (
                <tr key={idx}>
                  <td>{orderData.orderId}</td>
                  <td>
                    {orderData?.customerId
                      ? `${orderData.customerId.firstname || ""} ${
                          orderData.customerId.lastname || ""
                        }`
                      : ""}
                  </td>

                  <td>
                    {orderData.items.map((item) => (
                      // console.log(item, "item "),
                      <div key={item._id}>{item.name || null}</div>
                    ))}
                  </td>
                  <td>${orderData.totalAmount}</td>
                  <td>{orderData.paymentStatus}</td>
                  <td>{orderData.paymentMethod}</td>
                  <td>{orderData.shippingAddress}</td>
                  <td>{orderData.orderStatus}</td>
                  <td>{formatDate(orderData.updatedAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};
