import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Form, Card, Row, Col, Spinner } from "react-bootstrap";
// custom components
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import ImageForm from "../../../components/forms/ImageForm";
import AudiosForm from "./UploadAudiosForm";
import ModalStepsLoader from "../../../components/modals/loaderStepsModal";
import ParentCategorySelect from "./ParentCategorySelect";
// utils
import { wait } from "../../../utils/wait";
// custom hooks
import useModalWithSteps from "../../../hooks/useModalWithSteps";
import { useIsMounted } from "../../../hooks/useIsMounted";
// request
import filesRequests from "../../../request/files";
import categoriesRequests from "../../../request/categories";

import s from "./styles/createCategoryForm.module.css";

function CreateCategoryForm() {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const isMounted = useIsMounted();

  const { modalShow, setModalShow, modalCurrentStep, setModalCurrentStep } =
    useModalWithSteps();

  const [errors, setErrors] = useState({ errorExists: false, errorList: [] });
  const [inputValues, setInputValues] = useState({
    name: "",
    color: "#ffffff",
    backgroundColor: "#ffffff",
    parentCategoryId: null,
  });
  const [imageFile, setImageFile] = useState(null);
  const [songsObj, setSongsObj] = useState({ files: [], names: [], ids: [] });
  const [loadingData, setLoadingData] = useState(true);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectOccasion, setSelectOccasion] = useState(false);

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const _verifyBeforeSubmit = () => {
    let _errors = [];

    if (!inputValues.name) _errors.push("Category name needed");
    if (!inputValues.color) _errors.push("Color badge needed");
    if (!inputValues.backgroundColor) _errors.push("Background color needed");
    // if(!imageFile) 					_errors.push('Category image needed');

    return {
      errorExists: _errors.length > 0,
      errorList: _errors,
    };
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const { errorExists, errorList } = _verifyBeforeSubmit();
    let imageSaved;

    setErrors({ errorExists, errorList });

    if (errorExists) return;

    // check if need to update files or don't
    const needUpdateImage = imageFile && !(typeof imageFile === "string");

    try {
      setModalShow(true);
      // 1) first, save image
      setModalCurrentStep("Updating category");
      if (needUpdateImage) {
        imageSaved = await filesRequests.uploadImage(imageFile);
      }

      // 2) third, save category
      // setModalCurrentStep('Creating category');
      const updatedCategory = await categoriesRequests.updateCategory({
        _id: categoryId,
        parentCategoryId: inputValues.parentCategoryId || null,
        name: inputValues.name,
        color: inputValues.color,
        backgroundColor: inputValues.backgroundColor,
        ...(needUpdateImage && { imageId: imageSaved._id }),
      });
      console.log(updatedCategory);

      // 3) final, redirect
      setModalCurrentStep("Category succesfully updated, redirecting...");
      await wait(2500);
      navigate("/pages/categories");
    } catch (_err) {
      setModalShow(false);
      setModalCurrentStep("");
      setErrors({ errorExists: true, errorList: [_err.message] });
    }
  };

  useEffect(() => {
    categoriesRequests
      .getCategory(categoryId)
      .then((categoryData) => {
        if (isMounted.current) {
          const {
            name,
            color,
            backgroundColor,
            parentCategoryId,
            imageId,
            musicIds,
            ocassion,
          } = categoryData;
          setInputValues({ name, color, parentCategoryId, backgroundColor });
          setImageFile(imageId?.path);
          setSelectOccasion(ocassion);
          const songNames = musicIds?.map((m) => m.filename);
          const songFiles = musicIds?.map((m) => m.path);
          const songIds = musicIds?.map((m) => m._id);

          setSongsObj({ names: songNames, files: songFiles, ids: songIds });
        }
      })
      .finally(() => {
        if (isMounted.current) setLoadingData(false);
      });

    fetchCategories();
  }, [categoryId, isMounted]);

  const fetchCategories = async () => {
    try {
      const [selectedCategoryData] = await Promise.all([
        categoriesRequests.getselectcheckbox(),
      ]);

      if (isMounted.current) {
        if (selectedCategoryData) {
          console.log("selectedCategoryData", selectedCategoryData);
          setSelectedCategory(selectedCategoryData.data._id || null);
        }
      }
    } catch (error) {
      console.error("Error fetching categories or selected category:", error);
    } finally {
      if (isMounted.current) setLoadingData(false);
    }
  };

  const handleCheckboxChange = async (e, id) => {
    try {
      if (e.target.checked) {
        await categoriesRequests.deselectAllCategories();

        await categoriesRequests.selectCategory(id);
        setSelectedCategory(id);
      } else {
        console.log("deselecting category", id);

        await categoriesRequests.deselectAllCategories();

        setSelectedCategory(null);
      }
    } catch (error) {
      console.error("Error handling checkbox change:", error);
    }
  };

  const handleCheckboxOccasion = async (e, id) => {
    try {
      const response = await categoriesRequests.selectOccasion(id);
      if (response && response.category.ocassion) {
        setSelectOccasion(true);
      } else {
        setSelectOccasion(false);
      }

      console.log("response", selectOccasion);
    } catch (error) {
      console.error("Error handling checkbox change:", error);
    }
  };

  if (loadingData)
    return (
      <Spinner animation="border" className="d-flex justify-content-center" />
    );

  return (
    <div className={s.container}>
      <ModalStepsLoader show={modalShow} currentStepText={modalCurrentStep} />

      <SimplePageTitle>Update category</SimplePageTitle>

      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit} id="categoryForm">
            {errors.errorList.map((errMsg, idx) => (
              <Alert className="my-3" variant="danger" key={idx}>
                <div className="alert-message">{errMsg}</div>
              </Alert>
            ))}

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Category name</Form.Label>
                  <Form.Control
                    value={inputValues.name}
                    type="text"
                    name="name"
                    placeholder="name"
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <div className="d-flex">
                  <Form.Group className="mb-3 mx-5">
                    <Form.Label>Category color</Form.Label>
                    <Form.Control
                      value={inputValues.color}
                      type="color"
                      name="color"
                      onChange={handleChange}
                      style={{ width: "80px" }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 ">
                    <Form.Label>Background color</Form.Label>
                    <Form.Control
                      value={inputValues.backgroundColor}
                      type="color"
                      name="backgroundColor"
                      onChange={handleChange}
                      style={{ width: "80px" }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 mx-5">
                    <Form.Label> Pin Category </Form.Label>
                    <Form.Check
                      type="checkbox"
                      data-sub-new={categoryId}
                      value={categoryId}
                      checked={selectedCategory === categoryId}
                      onChange={(e) => handleCheckboxChange(e, categoryId)}
                      style={{ width: "80px" }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 mx-5">
                    <Form.Label> Ocassion </Form.Label>
                    <Form.Check
                      type="checkbox"
                      value={categoryId}
                      checked={selectOccasion}
                      onChange={(e) => handleCheckboxOccasion(e, categoryId)}
                      style={{ width: "80px" }}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>

            <ParentCategorySelect
              handleChange={handleChange}
              parentCategoryId={inputValues.parentCategoryId}
            />

            <ImageForm setImageFile={setImageFile} imageUrl={imageFile} />

            <AudiosForm
              categoryId={categoryId}
              setSongsObj={setSongsObj}
              songsObj={songsObj}
              setDisableSubmitBtn={setDisableSubmitBtn}
              isEditing
            />

            <Button
              disabled={disableSubmitBtn}
              type="submit"
              size="lg"
              variant="primary"
            >
              Update category
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CreateCategoryForm;
