import React, { useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import autocompleteRequests from "../../../request/autocompleteValues";
import { Coupons } from "./coupons";
import coupons from "../../../request/coupons";

const CreateCoupon = () => {
  const [formData, setFormData] = useState({
    couponCode: "",
    discount: "",
    startDate: "",
    endDate: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};

    if (!formData.couponCode.trim()) {
      errors.couponCode = "Coupon code is required.";
    }

    if (!formData.discount) {
      errors.discount = "Discount is required.";
    } else if (formData.discount <= 0 || formData.discount > 100) {
      errors.discount = "Discount must be between 1 and 100.";
    }

    if (!formData.startDate) {
      errors.startDate = "Start date is required.";
    }

    if (!formData.endDate) {
      errors.endDate = "End date is required.";
    } else if (formData.endDate < formData.startDate) {
      errors.endDate = "End date cannot be before start date.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!validate()) {
      Swal.fire({
        title: "Error",
        text: "Please correct the highlighted fields.",
        icon: "error",
      });
      return;
    }

    try {
      Swal.showLoading();

      const response = await coupons.saveCoupon(formData);

      if (response && response.success) {
        await Swal.fire({
          title: "Saved",
          text: "Redirecting to Coupons",
          icon: "success",
          timer: 2000,
        });

        navigate("/pages/coupons");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error saving coupon:", error);
      Swal.fire({
        title: "Error",
        text: error.message || "Failed to save the coupon.",
        icon: "error",
      });
    }
  };

  return (
    <div>
      <SimplePageTitle>Add Coupon</SimplePageTitle>

      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit} id="couponForm">
            <Form.Group className="mb-3">
              <Form.Label>Coupon Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter coupon code"
                name="couponCode"
                value={formData.couponCode}
                onChange={handleChange}
                isInvalid={!!errors.couponCode}
              />
              <Form.Control.Feedback type="invalid">
                {errors.couponCode}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Discount (%)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Discount"
                name="discount"
                value={formData.discount}
                onChange={handleChange}
                isInvalid={!!errors.discount}
              />
              <Form.Control.Feedback type="invalid">
                {errors.discount}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                isInvalid={!!errors.startDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                isInvalid={!!errors.endDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.endDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Button type="submit" size="lg" variant="primary">
              Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CreateCoupon;
