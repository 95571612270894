import React, { useState, useEffect } from "react";
import { Alert, Button, Form, Card, Row, Col, Spinner } from "react-bootstrap";
import { ExternalLink as ExternalLinkIcon } from "react-feather";
import { toast } from "react-toastify";
// custom components
import { SimplePageTitle } from "../../../components/titles/pageTitles";
// request
import miscellaneousRequest from "../../../request/miscellaneous";
import customerPurchasesRequests from "../../../request/customerPurchases";

// Check if a string is a valid JSON
function jsonValidator(jsonString) {
	try {
		JSON.parse(jsonString);
		return true;
	} catch (_err) {
		return false;
	}
}

const errToastOptions = { type: "error", position: "top-center", autoClose: 10000 };


function FixOrder() {
	const [isGoodOrderItemId, setIsGoodOrderItemId] = useState(false);
	const [isJsonValid, setIsJsonValid] = useState(false);
	const [latestOrderItemId, setLatestOrderItemId] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isCheckingOrderItemId, setIsCheckingOrderItemId] = useState(false);

	const [errors, setErrors] = useState({ errorExists: false, errorList: [] });
	const [rawJsonDataStr, setRawJsonDataStr] = useState("");
	const [inputValues, setInputValues] = useState({ 
		orderNumber: '',
		email: '',
		wooCartItemKey: '',
		wooOrderItemId: ''
	 });

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		setInputValues({
			...inputValues,
			[name]: value ? value.trim() : value
		});
	}

	// Manage the raw data input and try to fix it
	const handleRawDataChange = (ev) => {
		const { value } = ev.target;
		setRawJsonDataStr(value);
		
		const _isJsonValid = jsonValidator(value);

		// If the json is valid, try to fix it
		if(!_isJsonValid) {
			// Fix the json text values that contain the Hebrew unicode characters
			const step1 = value.replaceAll("u05", "\\u05");
			const step2 = step1.replaceAll(`"\\` ,  `\\"\\`);
			const step3 = step2.replaceAll(`:\\`, `:`);
			setRawJsonDataStr(step3);
			
			const _isJsonValidNow = jsonValidator(step3);
			setIsJsonValid(_isJsonValidNow);
		}
		else{
			setIsJsonValid(true);
		}

	}

	// Verify that all the needed fields are filled
	const _verifyBeforeSubmit = () => {
		let _errors = [];

		if(!inputValues.orderNumber) 	   _errors.push('Order number needed');
		if(!inputValues.email) 			   _errors.push('Customer email needed');
		if(!inputValues.wooCartItemKey)    _errors.push('Cart item key needed');
		if(!jsonValidator(rawJsonDataStr)) _errors.push('Invalid Raw (json) data');

		return {
			errorExists: _errors.length > 0,
			errorList: _errors
		}
	}

	// Submit the form
	const handleSubmit = async (ev) => {
		ev.preventDefault();
		const { errorExists, errorList } = _verifyBeforeSubmit();

		setErrors({ errorExists, errorList });
		
		if(errorExists) return;
		
		try {
			setIsLoading(true);
			const { orderNumber, email, wooCartItemKey, wooOrderItemId } = inputValues;
			const _rawJsonDataStr = rawJsonDataStr;

			// Get the order item with the problems from the wordpress
			const orderWithProblems = await customerPurchasesRequests.getWooOrderItem({
				wooCartItemKey,
				wooOrderItemId,
				orderId: orderNumber
			});

			if(!orderWithProblems?.customized_item_preview){
				toast("Can't get data from order", errToastOptions);
				return;
			}

			// Update the order item with the new data for wordpress
			const updatedOrderItem = await customerPurchasesRequests.updateWooOrderItem({
				woo_order_id: orderNumber,
				woo_order_item_id: wooOrderItemId,
				woo_cart_item_key: wooCartItemKey,
				customization_data: JSON.stringify(JSON.parse(_rawJsonDataStr)),
				customized_item_preview: orderWithProblems.customized_item_preview
			});

			if(!updatedOrderItem){
				toast("Error updating order in wordpress", errToastOptions);
				return;
			}

			// Update the order item with the new data for the database (mongodb)
			const updatedOrderItemInDB = await customerPurchasesRequests.initEditCount({
				wooCartItemKey,
				orderId: orderNumber,
				// wooOrderItemId,
				customerEmail: email
			})

			if(!updatedOrderItemInDB){
				toast("Error updating order in mongodb database", errToastOptions);
				return;
			}

			toast("Order fixed successfully", { type: "success", position: "top-center" });

		} catch (_err) {
			console.log("Error in handleSubmit", _err.message);
			toast("Error fixing order", errToastOptions);
		}
		finally{
			setIsLoading(false);
		}
	}

	// Check if the order item id is valid
	const handleCheckOrderItemId = async () => {
		try {
			const { wooOrderItemId, wooCartItemKey, orderNumber } = inputValues;
			setIsCheckingOrderItemId(true);

			if(!orderNumber) return toast("Order number needed", { type: "error", position: "top-center" });
			if(!wooCartItemKey) return toast("Customization data key needed", { type: "error", position: "top-center" });
			if(!wooOrderItemId) return toast("Woocommerce order item id data key needed", { type: "error", position: "top-center" });

			const orderExistWithThatItemId = await customerPurchasesRequests.getWooOrderItem({
				wooCartItemKey,
				wooOrderItemId,
				wooOrderId: orderNumber
			});

			setIsGoodOrderItemId(orderExistWithThatItemId !== null);

		} catch (_err) {
			console.log("Error in handleCheckOrderItemId", _err.message);
		}
		finally{
			setIsCheckingOrderItemId(false);
		}
	}

	// Get latest order item id
	useEffect(() => {
		const _getLatestOrderItemId = async () => {
			try {
				const id = await miscellaneousRequest.getLatestOrderItemId();
				setLatestOrderItemId(id);
			} catch (_err) {
				console.log("Error in getLatestOrderItemId", _err.message);
			}
		}

		_getLatestOrderItemId();
	}, []);

	return (
		<div>
			<SimplePageTitle>
				Fix Wordpress Order
			</SimplePageTitle>
			
			<Card>
				<Card.Body>
					<Form onSubmit={handleSubmit} id="fixOrder">

						{
							errors.errorList.map( (errMsg, idx) => (
								<Alert className="my-3" variant="danger" key={idx}>
									<div className="alert-message">{errMsg}</div>
								</Alert>
								)
							)
						}

						<Row>
							<Col>
                                {/* Order number */}
								<Form.Group className="mb-3">
									<Form.Label>Order number</Form.Label>
									<Form.Control 
										type="text" 
										name="orderNumber" 
										placeholder="Order number" 
										onChange={handleChange} 
										autoComplete="off"
										required
										value={inputValues.orderNumber}
									/>
								</Form.Group>
							</Col>
                            <Col>
                                {/* Customer Email */}
                                <Form.Group className="mb-3 mx-5">
                                    <Form.Label>Customer Email</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        name="email"  
                                        onChange={handleChange}
										value={inputValues.email}
                                    />
                                </Form.Group>                   
                            </Col>
							<Col>
                                {/* Customization data key */}
                                <Form.Group className="mb-3 mx-5">
                                    <Form.Label>Customization data key</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="wooCartItemKey"  
                                        onChange={handleChange}
										value={inputValues.wooCartItemKey}
                                    />
                                </Form.Group>
							</Col>
						</Row>

                        <Row>
                            <Col>
                                {/* Customization data text */}
                                <Form.Group className="mb-3">
									<div className="d-flex flex-column align-items-start">
                                        <Form.Label>Raw card data</Form.Label>
										{
											rawJsonDataStr.length > 0 && (
												<Form.Label>
													{
														isJsonValid ? 
														<span className="text-success">Valid data ✔️</span> : 
														<span className="text-danger">Invalid data ❌</span>
													}
												</Form.Label>
											)
										}
                                    </div>
                                    <Form.Control 
                                        as="textarea"
                                        name="raw_data" 
                                        placeholder="Paste here the full customization data from the order" 
                                        onChange={handleRawDataChange} 
										value={rawJsonDataStr}
                                        autoComplete="off"
                                        required
										className="mb-2"
										rows={7}
										style={{ minHeight: "150px"}}
                                    />
									<div className="d-flex align-items-center">
										<a
											href="https://codesandbox.io/p/sandbox/json-fix-gq942w?file=%2Fsrc%2Ffields.json" 
											target="_blank" 
											rel="noreferrer"
										>
											format checker
										</a>
										<ExternalLinkIcon size={18} className="mx-2"/>
									</div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {/* Customization data text */}
                                <Form.Group className="mb-3 bg-light p-3">
                                    <div className="d-flex flex-column align-items-start">
                                        <Form.Label>Try woocommerce order item id</Form.Label>
                                        {
											inputValues.wooOrderItemId.length > 0 && (
												<Form.Label>
													{
														isGoodOrderItemId ? 
														<span className="text-success">Good order item id ✔️</span> : 
														<span className="text-danger">Bad order item id ❌</span>
													}
                                        		</Form.Label>
											)
										}
										{
											isCheckingOrderItemId && (
												<Spinner animation="border" variant="primary" size="sm" />
											)
										}
                                    </div>
                                    <Form.Control 
                                        type="text"
                                        name="wooOrderItemId" 
                                        placeholder="Order item id" 
                                        onChange={handleChange} 
										value={inputValues.wooOrderItemId}
                                        autoComplete="off"
                                        required
                                    />
                                    <div className="d-flex flex-column align-items-start">
                                        <b>Latest successful order item id is: {latestOrderItemId}</b>
                                        <div>
                                            <Button 
												type="button" 
												size="md" 
												variant="info"
												onClick={handleCheckOrderItemId}
											>
													Try
											</Button>
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

						<Button 
							type="submit" 
							size="lg" 
							variant="primary"
							disabled={isLoading}
						>
							Fix
						</Button>
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
}

export default FixOrder;
