import ajax from "./ajax";
import { API_BASE_URL } from "../config";

const coupons = {};

coupons.saveCoupon = async function (body) {
  const url = `${API_BASE_URL}/coupons/CreateCoupon`;
  try {
    const response = await ajax.post(url, body, { token: true });

    if (response.success) {
      return response;
    } else {
      throw new Error(response || "Failed to save coupon");
    }
  } catch (error) {
    console.error("Error saving coupon:", error);

    return {
      success: false,
      message:
        error.message || "An unexpected error occurred while saving the coupon",
    };
  }
};

coupons.getCouponById = async function (couponId) {
  const url = `${API_BASE_URL}/coupons/getSingleCouponAdmin/${couponId}`;
  try {
    const response = await ajax.get(url, { token: true });

    // console.log("new response", response);
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to fetch coupon");
    }
  } catch (error) {
    console.error("Error fetching coupon:", error);
    return {
      success: false,
      message: error.message || "An unexpected error occurred.",
    };
  }
};

coupons.getCoupons = async function () {
  const url = `${API_BASE_URL}/coupons/getCoupons`;
  try {
    const response = await ajax.get(url, { token: true });

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to fetch coupons.");
    }
  } catch (error) {
    console.error("Error fetching coupons:", error);
    return {
      success: false,
      message: error.message || "An unexpected error occurred.",
    };
  }
};

coupons.deleteCoupon = async function ({ _id }) {
  const url = `${API_BASE_URL}/coupons/DeleteAutocompleteValue`;

  console.log("the ID in request function", _id);

  const deleted = await ajax.post(url, { _id }, { token: true });
  return deleted;
};

coupons.updateCouponStatus = async function (couponId, body) {
  const url = `${API_BASE_URL}/coupons/updateStatus/${couponId}/status`;
  try {
    const response = await ajax.post(url, body, { token: true });

    if (response.success) {
      return response;
    } else {
      throw new Error(response.data.message || "Failed to update coupon");
    }
  } catch (error) {
    console.error("Error update coupon:", error);

    return {
      success: false,
      message:
        error.message || "An unexpected error occurred while update the coupon",
    };
  }
};

coupons.updateCoupon = async function (couponId, body) {
  const url = `${API_BASE_URL}/coupons/updateCoupon/${couponId}`;
  try {
    const response = await ajax.put(url, body, { token: true });

    return response;
  } catch (error) {
    console.error("Error update coupon:", error);

    return {
      success: false,
      message:
        error.message || "An unexpected error occurred while update the coupon",
    };
  }
};

export default coupons;
