import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import { Button, Spinner, Table } from "react-bootstrap";
import coupons from "../../../request/coupons";
import Swal from "sweetalert2";
import { EditCouponModal } from "./editCouponModal";

export const Coupons = () => {
  const [couponList, setCouponList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [couponId, setCouponId] = useState(null);

  const [formData, setFormData] = useState({
    code: "",
    discount: "",
    startDate: "",
    endDate: "",
  });
  const [showEditModal, setShowEditModal] = useState(false);

  const fetchCoupons = async () => {
    setLoading(true);
    try {
      const response = await coupons.getCoupons();
      if (response) {
        setCouponList(response);
      } else {
        setError(response.message);
      }
    } catch (err) {
      console.error("Failed to fetch coupons:", err);
      setError("Failed to fetch coupons");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  function formatDate(date) {
    const mdate = new Date(date);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return mdate.toLocaleDateString("en-US", options);
  }

  const handleStatusToggle = async (couponId, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      const response = await coupons.updateCouponStatus(couponId, {
        active: newStatus,
      });

      if (response.success) {
        Swal.fire("Success", "Coupon status updated successfully.", "success");

        setCouponList((prevList) =>
          prevList.map((coupon) =>
            coupon._id === couponId ? { ...coupon, active: newStatus } : coupon
          )
        );
      } else {
        throw new Error(response.message || "Failed to update coupon status.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      Swal.fire("Error", error.message || "Failed to update status.", "error");
    }
  };

  const handleEdit = async (couponId) => {
    try {
      const response = await coupons.getCouponById(couponId);
      setCouponId(couponId);
      if (response) {
        setFormData({
          code: response.code || "",
          discount: response.discount || "",
          startDate: response.start_date || "",
          endDate: response.end_date || "",
        });

        setShowEditModal(true);
      } else {
        throw new Error(response.message || "Failed to fetch coupon details.");
      }
    } catch (error) {
      console.error("Error fetching coupon:", error);
      Swal.fire({
        title: "Error",
        text: error.message || "Failed to fetch coupon details.",
        icon: "error",
      });
    }
  };

  const handleSave = async () => {
    try {
      const response = await coupons.updateCoupon(formData._id, formData);
      if (response.success) {
        Swal.fire({
          title: "Success",
          text: "Coupon updated successfully.",
          icon: "success",
        });
        setShowEditModal(false);
      } else {
        throw new Error(response.message || "Failed to update coupon.");
      }
    } catch (error) {
      console.error("Error updating coupon:", error);
      Swal.fire({
        title: "Error",
        text: error.message || "Failed to update coupon.",
        icon: "error",
      });
    }
  };

  const handleClose = () => {
    setShowEditModal(false);
    fetchCoupons();
  };

  return (
    <>
      <Helmet title="Principal Order" />

      <div className="d-flex justify-content-between">
        <SimplePageTitle>Coupon List</SimplePageTitle>
      </div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Coupon Code</th>
                <th> Start date</th>
                <th>End date</th>
                <th> Discount</th>
                <th> Status </th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {couponList.map((coupon, idx) => (
                <tr key={idx}>
                  <td>{coupon.code}</td>
                  <td>{formatDate(coupon.start_date)}</td>
                  <td>{formatDate(coupon.end_date)}</td>
                  <td>{coupon.discount}%</td>
                  <td>
                    <Button
                      variant={coupon.active ? "danger" : "success"}
                      onClick={() =>
                        handleStatusToggle(coupon._id, coupon.active)
                      }
                    >
                      {coupon.active ? "Deactivate" : "Activate"}
                    </Button>
                  </td>
                  <td>
                    <td>
                      <Button onClick={() => handleEdit(coupon._id)}>
                        Edit
                      </Button>
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {showEditModal && (
            <EditCouponModal
              show={showEditModal}
              handleClose={handleClose}
              formData={formData}
              onFormChange={setFormData}
              couponId={couponId}
            />
          )}
        </div>
      )}
    </>
  );
};
