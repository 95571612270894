import React, { useState } from "react";
import { Form, Card } from "react-bootstrap";
import { Button } from "antd";
// custom components
import { SimplePageTitle } from "../../../../components/titles/pageTitles";
import PurchasedItem from "./purchasesListItem";
// requests
import customerPurchasesRequests from "../../../../request/customerPurchases";
// utils
import parseEditLinkParams from "./parseEditLinkParams";
// import mockData from './orderDataMock';

const EditCustomerCardSearch = () => {
  const [inputValues, setInputValues] = useState({ email: "", link: "" });
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const { email, link } = inputValues;
    let _results;
    if (!email && !link) return;

    setLoading(true);
    setResults([]);

    try {
      if (email) {
        _results =
          (await customerPurchasesRequests.getCustomerPurchasesByEmail({
            customerEmail: email,
          })) || [];
      } else if (link) {
        const linkParams = parseEditLinkParams(link);
        const r = await customerPurchasesRequests.getWooOrderItem(linkParams);
        // const r = mockData.data;
        _results = r ? [r] : [];
      }
      console.log(_results);
      setResults(_results);

      let _notFoundMessage = email
        ? "No results for " + email
        : "No results associated with this link.";
      setNotFoundMessage(_results.length ? "" : _notFoundMessage);
    } catch (error) {
      console.log("can't get purchases", error.message);
    } finally {
      setLoading(false);
      setInputValues({ email: "", link: "" });
    }
  };

  return (
    <div>
      <SimplePageTitle>Search customer orders to edit</SimplePageTitle>

      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>By customer email</Form.Label>
              <Form.Control
                name="email"
                value={inputValues.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>By edit purchase link</Form.Label>
              <Form.Control
                name="link"
                value={inputValues.link}
                onChange={handleChange}
              />
            </Form.Group>

            <Button
              type="primary"
              onClick={handleSubmit}
              size="large"
              loading={loading}
            >
              Search
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {notFoundMessage && (
        <Card>
          <Card.Body>{notFoundMessage}</Card.Body>
        </Card>
      )}

      {results.map((result, idx) => (
        <PurchasedItem
          key={idx}
          {...result}
          fullCustomizationData={result.orderRequest.customization_data}
          customizedItemPreview={result.orderRequest.customized_item_preview}
          resultFullData={result}
        />
      ))}
    </div>
  );
};

export default EditCustomerCardSearch;
