import './fullScreenLoader.css';

function FullScreenLoader({ text }){
    return (
        <div className="full-container-modal">
            <div className="fcm-loader">
                <div className="lds-ripple"><div></div><div></div></div>
            </div>
        
            {
                text && (
                    <h1>{text}</h1>
                )
            }
        </div>
    )
}

export default FullScreenLoader;