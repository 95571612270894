import {
  Bell,
  Calendar,
  CheckSquare,
  Grid,
  Heart,
  Layout,
  List,
  PieChart,
  MapPin,
  Share,
  ShoppingCart,
  FileText,
  Home,
  User,
  Hash,
  Edit3,
  Image,
  Tool,
  ShoppingBag,
  Disc,
} from "react-feather";

const pagesSection = [
  {
    href: "#",
    icon: Layout,
    title: "Categories",
    children: [
      {
        title: "Create Category",
        href: "/pages/createCategory",
      },
      {
        title: "Categories List",
        href: "/pages/categories",
      },
      // {
      //   title: "Categories Pining",
      //   href: "/pages/categoriesPining",
      // },
    ],
  },

  {
    href: "#",
    icon: ShoppingCart,
    title: "Products",
    children: [
      {
        title: "Create Product",
        href: "/pages/createProduct",
      },
      {
        title: "View Products",
        href: "/pages/products",
      },
    ],
  },

  {
    href: "#",
    icon: FileText,
    title: "Fonts",
    children: [
      {
        title: "Upload Fonts",
        href: "/pages/saveFonts",
      },
      {
        title: "Fonts List",
        href: "/pages/fontsList",
      },
    ],
  },

  {
    href: "#",
    icon: Hash,
    title: "Tags",
    children: [
      {
        title: "View Tags",
        href: "/pages/tags",
      },
      {
        title: "View Tag Categories",
        href: "/pages/tagCategories",
      },
    ],
  },

  {
    href: "/pages/autofillableFields",
    icon: Edit3,
    title: "Autofillable Fields",
  },

  {
    href: "#",
    icon: Home,
    title: "Hall Name/Address",
    children: [
      {
        title: "Save Hall name/address",
        href: "/pages/saveHall",
      },
      {
        title: "Halls List",
        href: "/pages/halls",
      },
    ],
  },

  {
    href: "#",
    icon: Home,
    title: "Shul Name/Address",
    children: [
      {
        title: "Save Shul name/address",
        href: "/pages/saveShul",
      },
      {
        title: "Shuls List",
        href: "/pages/shuls",
      },
    ],
  },

  {
    href: "#",
    icon: User,
    title: "Names of people",
    children: [
      {
        title: "Add names",
        href: "/pages/savePeopleNames",
      },
      {
        title: "Names List",
        href: "/pages/peopleNames",
      },
    ],
  },

  {
    href: "/pages/watermarks",
    icon: Image,
    title: "Watermarks",
  },
  {
    href: "/pages/edit-customer-card",
    icon: Edit3,
    title: "Edit Customer Card",
  },
  {
    href: "/pages/fix-order",
    icon: Tool,
    title: "Fix Order",
  },
  {
    href: "/pages/OrderList",
    icon: ShoppingBag,
    title: "Order",
  },

  {
    href: "#",
    icon: Disc,
    title: "Coupons",
    children: [
      {
        title: "Create Coupon",
        href: "/pages/createCoupon",
      },
      {
        title: "View Coupons",
        href: "/pages/coupons",
      },
    ],
  },
];

const componentsSection = [
  {
    href: "/ui",
    icon: Grid,
    title: "UI Elements",
    children: [
      {
        href: "/ui/alerts",
        title: "Alerts",
      },
      {
        href: "/ui/buttons",
        title: "Buttons",
      },
      {
        href: "/ui/cards",
        title: "Cards",
      },
      {
        href: "/ui/carousel",
        title: "Carousel",
      },
      {
        href: "/ui/embed-video",
        title: "Embed Video",
      },
      {
        href: "/ui/general",
        title: "General",
      },
      {
        href: "/ui/grid",
        title: "Grid",
      },
      {
        href: "/ui/modals",
        title: "Modals",
      },
      {
        href: "/ui/offcanvas",
        title: "Offcanvas",
      },
      {
        href: "/ui/tabs",
        title: "Tabs",
      },
      {
        href: "/ui/typography",
        title: "Typography",
      },
    ],
  },
  {
    href: "/icons",
    icon: Heart,
    title: "Icons",
    badge: "1500+",
    children: [
      {
        href: "/icons/feather",
        title: "Feather",
      },
      {
        href: "/icons/font-awesome",
        title: "Font Awesome",
      },
    ],
  },
  {
    href: "/forms",
    icon: CheckSquare,
    title: "Forms",
    children: [
      {
        href: "/forms/layouts",
        title: "Layouts",
      },
      {
        href: "/forms/basic-inputs",
        title: "Basic Inputs",
      },
      {
        href: "/forms/input-groups",
        title: "Input Groups",
      },
      {
        href: "/forms/floating-labels",
        title: "Floating Labels",
      },
    ],
  },
  {
    href: "/tables",
    icon: List,
    title: "Tables",
  },
];

const pluginsSection = [
  {
    href: "/form-plugins",
    icon: CheckSquare,
    title: "Form Plugins",
    children: [
      {
        href: "/form-plugins/advanced-inputs",
        title: "Advanced Inputs",
      },
      {
        href: "/form-plugins/formik",
        title: "Formik",
        badge: "New",
      },
      {
        href: "/form-plugins/editors",
        title: "Editors",
      },
    ],
  },
  {
    href: "/advanced-tables",
    icon: List,
    title: "Advanced Tables",
    children: [
      {
        href: "/advanced-tables/pagination",
        title: "Pagination",
      },
      {
        href: "/advanced-tables/column-sorting",
        title: "Column Sorting",
      },
      {
        href: "/advanced-tables/column-filtering",
        title: "Column Filtering",
      },
      {
        href: "/advanced-tables/row-expanding",
        title: "Row Expanding",
      },
      {
        href: "/advanced-tables/row-selection",
        title: "Row Selection",
      },
    ],
  },
  {
    href: "/charts",
    icon: PieChart,
    title: "Charts",
    badge: "New",
    children: [
      {
        href: "/charts/chartjs",
        title: "Chart.js",
      },
      {
        href: "/charts/apexcharts",
        title: "ApexCharts",
        badge: "New",
      },
    ],
  },
  {
    href: "/notifications",
    icon: Bell,
    title: "Notifications",
  },
  {
    href: "/maps",
    icon: MapPin,
    title: "Maps",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
  {
    href: "/calendar",
    icon: Calendar,
    title: "Calendar",
  },
  {
    href: "/404",
    icon: Share,
    title: "Multi Level",
    children: [
      {
        href: "/404",
        title: "Two Levels",
        children: [
          {
            href: "/404",
            title: "Item 1",
          },
          {
            href: "/404",
            title: "Item 2",
          },
        ],
      },
      {
        href: "/404",
        title: "Three Levels",
        children: [
          {
            href: "/404",
            title: "Item 1",
            children: [
              {
                href: "/404",
                title: "Item 1",
              },
              {
                href: "/404",
                title: "Item 2",
              },
            ],
          },
          {
            href: "/404",
            title: "Item 2",
          },
        ],
      },
    ],
  },
];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
];

export default navItems;
