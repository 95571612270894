import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Spinner } from "reactstrap";
// custom components
import SimpleModal from "../../../../components/modals/simpleModal";
// images
import imageIcon from "../../../../assets/img/icons/image-icon.svg";
import pdfIcon from "../../../../assets/img/icons/file-icon.svg";
import videoIcon from "../../../../assets/img/icons/videocam-icon.svg";
// utils
import isMobile from "../../../../utils/detectMobile";
import {
  getSmallerCanvasBase64ImageForJPEG,
  generateVideoAndGetURL,
  createPDFAndDownloadV2,
  initCanvas,
} from "../../../../utils/handleCreateFiles";
import {
  downloadImage,
  downloadVideo,
} from "../../../../utils/downloadManager";
import { forceRenderUpdate } from "../../../../utils/canvas2";
// style
import "./style/downloadCard.css";

const DownloadCard = ({ canvas, qrText, songData, productData }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pdfTagSize, setPdfTagSize] = useState(null);
  const [videoUrlCreated, setVideoUrlCreated] = useState("");
  const [isCreatingVideo, setIsCreatingVideo] = useState(false);
  const [tempCanvasState, setTempCanvasState] = useState({ loading: true });

  const handleShowModalAndLoadTempCanvas = async () => {
    setTempCanvasState({ loading: true });
    setShowModal(true);
    const canvasObjects = canvas.getObjects();

    // create temp canvas with full resolution original image
    const tempCanvas = await initCanvas({
      canvasObjects,
      QRText: qrText,
      product: {
        productFilesId: productData.filesId._id,
        productName: productData.name,
      },
    });
    await forceRenderUpdate(tempCanvas, true, true, true);
    setTempCanvasState({ loading: false });
  };

  const downloadAs = async (format) => {
    console.log(canvas, "canvas");
    return;
    if (tempCanvasState.loading) {
      return;
    }
    const productName = productData.name || "download";

    if (format === "jpeg" || format === "png") {
      try {
        const base64Image = await getSmallerCanvasBase64ImageForJPEG();

        if (isMobile.iOS()) {
          downloadImage(base64Image, productName, "jpeg");
        } else {
          let img = base64Image;
          const a = document.createElement("a");
          a.download = productName + "." + "jpg";
          a.href = img;
          a.click();
        }
        return;
      } catch (err) {
        console.error(err.message);
      }
    }

    if (format === "video") {
      if (isCreatingVideo) return;

      try {
        setIsCreatingVideo(true);
        const imgNameExt = productData.originalPath || "image.jpg";
        const song = songData || null;
        const videoUrl = await generateVideoAndGetURL(imgNameExt, song);
        // actually download
        await downloadVideo(videoUrl, productName);
        setVideoUrlCreated(videoUrl);
        setIsCreatingVideo(false);
      } catch (err) {
        console.error("Error creating video", err.message);
        setIsCreatingVideo(false);
      }
    }
  };

  const downloadAsPdf = () => {
    const productName = productData.name || "download";
    createPDFAndDownloadV2(productName, pdfTagSize);
  };

  // set poster sizes based on tags
  useEffect(() => {
    if (productData) {
      const tags = productData.tags || [];
      if (tags.length) {
        const tagDimension = tags.join(" ").match(/\d+\.?\d+x\d+\.?\d+/i); // dimensions like 8.5x11 or 11x8.5
        if (tagDimension) {
          const [width, height] = tagDimension[0].split(/x/i).map(Number);
          setPdfTagSize({ width, height });
        }
      }
    }
  }, [productData]);

  return (
    <>
      <SimpleModal show={showModal} onClose={() => setShowModal(false)}>
        <div className="dp__buttons">
          <div
            id="dp__download-overlay"
            style={{ display: tempCanvasState.loading ? "flex" : "none" }}
          >
            <Spinner type="border" style={{ color: "#af40f7" }} />
          </div>
          {/* pdf button */}
          <div className="dp__btn-item">
            <div
              className={`dp__format-button ${
                tempCanvasState.loading ? "disabled" : ""
              }`}
              onClick={downloadAsPdf}
            >
              <img src={pdfIcon} alt="pdf icon" className="dp_icon_btn_img" />
              <div className="dp__format-text">PDF</div>
            </div>
            <div className="dp__btn-item-description">for print</div>
          </div>
          {/* jpg button */}
          <div className="dp__btn-item">
            <div
              onClick={() => downloadAs("jpeg")}
              className={`dp__format-button ${
                tempCanvasState.loading ? "disabled" : ""
              }`}
            >
              <img src={imageIcon} alt="png icon" width={25} />
              <div className="dp__format-text">JPG</div>
            </div>
            <div className="dp__btn-item-description">for screens</div>
          </div>

          {/* video button */}
          {songData && (
            <div className="dp__btn-item">
              <div
                className={`dp__format-button ${
                  tempCanvasState.loading || isCreatingVideo ? "disabled" : ""
                }`}
                onClick={() => downloadAs("video")}
              >
                <img
                  src={videoIcon}
                  alt="video icon"
                  className="dp_icon_btn_img"
                />
                <div className="dp__format-text">MP4</div>

                {isCreatingVideo && (
                  <div id="video-load-overlay">
                    {" "}
                    <Spinner type="border" style={{ color: "#af40f7" }} />{" "}
                  </div>
                )}
              </div>

              <div className="dp__btn-item-description">Video</div>
            </div>
          )}
        </div>
      </SimpleModal>

      <div className="hrLine mb-2" />

      <div className="download-btn-container editor-padding">
        <Button
          type="primary"
          loading={loading}
          onClick={handleShowModalAndLoadTempCanvas}
        >
          Download Card
        </Button>
      </div>
    </>
  );
};

export default DownloadCard;
