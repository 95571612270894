import { jsPDF } from "jspdf";
import { fabric } from "fabric";
// requests
import filesRequests from "../request/files";
import productUtilsRequests from "../request/productsUtils";
// utils
import { wait } from "./wait";
import { reverseNumbers, hasHebrewCharacters } from "./text";
import { createFabricQRImage } from "./createFabricQRImage";
import {
  assingObjectsToCanvas,
  setCustomFontsWhenLoaded,
  setCanvasAtResoution,
} from "./canvas2";

// global variables
let imageWidth, imageHeight, tempCanvas;
const CANVAS_SIZE = 800;

function _getScaleMultiplier(isForQR = false) {
  if (isForQR) {
    if (imageWidth <= 3000) {
      return (imageWidth * 2) / CANVAS_SIZE;
    }
    return imageWidth / CANVAS_SIZE;
  }

  return imageWidth / CANVAS_SIZE;
}

function _getResizedWithAndHeight(maxWidth = 4000, maxHeight = 4000) {
  let width = imageWidth;
  let height = imageHeight;

  // Change the resizing logic
  if (width > height) {
    if (width > maxWidth) {
      height = Math.floor(height * (maxWidth / width));
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.floor(width * (maxHeight / height));
      height = maxHeight;
    }
  }

  return { width, height };
}

async function _addHighResolutionQRCode(QRText) {
  console.log("re-creating QR Code");
  const scaleMultiplier = _getScaleMultiplier(false);
  // remove low resolution qr image
  const currentQRGroup = tempCanvas
    .getObjects()
    .find((obj) => obj.type === "group");

  if (currentQRGroup) {
    const { left, top, width } = currentQRGroup;
    tempCanvas.remove(currentQRGroup);

    // re-create qr code with better resolution
    const fabricQrImage = await createFabricQRImage({
      size: width,
      phoneNumber: QRText,
      left,
      top,
      pageName: "download",
      posterWidth: tempCanvas.width,
      isForDownloading: true,
    });

    if (imageWidth <= 3000) {
      fabricQrImage.scale(0.52);
    }

    fabricQrImage.setCoords();
    tempCanvas.add(fabricQrImage);

    await wait(50); // the image needs a little bit of time before get rendered

    // re-scale fontSize if the object lose his fontSize rescaled value
    for (let obj of tempCanvas.getObjects()) {
      if (
        obj.label === "QR Code" ||
        obj.label === "QR Code Image" ||
        obj.type === "group"
      )
        continue;

      if (obj.originalFontSize !== obj.fontSize / scaleMultiplier) {
        console.log("fontSize scaled again");
        obj.fontSize *= scaleMultiplier;
      }
    }

    tempCanvas.renderAll();
  }
}

// function __testGroupValues(canvas, showShould = true) {
//     const scaleMultiplier = _getScaleMultiplier();
//     console.log("p canvas", canvas);
//     const group = canvas.getObjects().find(o => o.type === 'group');
//     const { left, top, width, height } = group;
//     console.log("current group values", { left, top, width, height });

//     if (showShould) {
//         console.log("should be these values",
//             Object.entries({ left, top, width, height })
//                 .map(([key, value]) => {
//                     console.log(key, value * scaleMultiplier);
//                 }));
//     }
// }

async function _setObjectsAndScale(canvasObjects, canvas = null) {
  const scaleMultiplier = _getScaleMultiplier();
  const _tempCanvas = canvas || tempCanvas;

  await assingObjectsToCanvas(_tempCanvas, canvasObjects, true);
  await setCustomFontsWhenLoaded(_tempCanvas);

  // scale properties
  for (let obj of _tempCanvas.getObjects()) {
    if (obj.label === "QR Code" || obj.label === "QR Code Image") continue;

    obj.originalFontSize = obj.fontSize; //  important to check if font resize was good
    obj.originalTextWidth = obj.width; //  important to check if font resize was good

    obj.width *= scaleMultiplier;
    obj.left *= scaleMultiplier;
    obj.top *= scaleMultiplier;
    obj.fontSize *= scaleMultiplier;
    obj.setCoords();
  }

  _tempCanvas.renderAll();
}

async function createPDFAndDownloadV2(productName, sizeObj) {
  let width, height, unit, fileName;
  const { width: resizedWidth, height: resizedHeight } =
    _getResizedWithAndHeight();

  if (!sizeObj) {
    unit = "px";
    width = resizedWidth;
    height = resizedHeight;
    fileName = productName + " - full size";
  } else {
    unit = "in";
    width = sizeObj.width;
    height = sizeObj.height;
    fileName = productName + ` ${sizeObj.width} x ${sizeObj.height} inches`;
  }

  const opts = {
    orientation: imageHeight > imageWidth ? "p" : "l",
    format: [width, height],
    unit,
  };
  const pdf = new jsPDF(opts);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  const imgElmt = new Image(resizedWidth, resizedHeight);
  imgElmt.src = await getSmallerCanvasBase64ImageForJPEG();

  pdf.addImage(imgElmt, "JPEG", 0, 0, pdfWidth, pdfHeight);
  // download
  pdf.save(`${fileName}.pdf`);
}

async function generateVideoAndGetURL(imageNameWithExt, songSelected) {
  if (!songSelected) return "";
  console.log("try to generate video");

  const songName = songSelected.path.split("/").pop();
  const base64Image = await _getSmallerCanvasBase64Image(1200);

  const savedVideoUrl = await productUtilsRequests.createVideo({
    base64Image,
    songName,
    imageName: imageNameWithExt,
    originalImageWidth: 1200,
  });

  return savedVideoUrl;
}

const _cloneCanvas = async (canvas = null) => {
  return new Promise((resolve) => {
    (canvas || tempCanvas).clone(
      (clonedCanvas) => {
        clonedCanvas.renderAll();
        resolve(clonedCanvas);
      },
      ["originalFontSize", "originalTextWidth"]
    );
  });
};

const _reverseNumbers = (canvas) => {
  for (const obj of canvas.getObjects()) {
    if (obj.type === "textbox") {
      if (hasHebrewCharacters(obj.text) && /[0-9]/.test(obj.text)) {
        obj.text = reverseNumbers(obj.text);
      }
    }
  }
};

// get a lightweight image, eg 5000px width to 1200px width
const _getSmallerCanvasBase64Image = async (newWidth = 1200) => {
  const cloned = await _cloneCanvas();

  setCanvasAtResoution(cloned, newWidth, imageWidth, {
    width: imageWidth,
    height: imageHeight,
  });
  // reverse numbers if text is hebrew, specially for hour format
  _reverseNumbers(cloned);

  const resizedBase64 = cloned.toDataURL({
    format: "jpeg",
  });

  return resizedBase64;
};

const getSmallerCanvasBase64ImageForJPEG = async () => {
  const { width, height } = _getResizedWithAndHeight();
  const base64 = await _getSmallerCanvasBase64Image(width);

  const img = new Image();
  img.src = base64;

  return new Promise((resolve) => {
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      const dataURL = canvas.toDataURL("image/jpeg");
      resolve(dataURL);
    };
  });
};

const getCanvas = () => {
  return tempCanvas;
};

const getPosterbase64Image = (format = "jpeg") => {
  const base64Image = tempCanvas.toDataURL({
    format,
  });
  return base64Image;
};

function _checkForMovedBoxesAndReadjust(_tempCanvas) {
  const scaleMultiplier = _getScaleMultiplier();

  for (const obj of _tempCanvas.getObjects()) {
    if (obj.type === "textbox") {
      let extraCount = 0;
      // reduce font if box width is bigger than max width (specially for multiline boxes)
      // using a variable called "extraCount" to checking box width, because if you reduce the box width
      // and the text is still very large (fontSize) the box width  won't change
      // so we need to remember the last fontSize
      extraCount = obj.fontSize * scaleMultiplier;
      const scaledMaxWidth = obj.maxWidth * scaleMultiplier;

      while (obj.width > scaledMaxWidth) {
        extraCount--;
        obj.fontSize = extraCount;
        _tempCanvas.renderAll();
        obj.width = scaledMaxWidth;
        _tempCanvas.renderAll();
        console.log("reducing box width and font size to fit maxWidth");
      }
    }
  }
}

function _testImg(canvas = null, borderColor = "red") {
  const img2 = new Image();
  img2.src = (canvas || tempCanvas).toDataURL({
    format: "jpeg",
  });
  document.body.append(img2);
  img2.style.marginTop = "600px";
  img2.style.width = "800px";
  img2.style.border = "3px solid " + borderColor;

  return;
}

const initCanvas = ({ canvasObjects, product, QRText }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const canvasElmt = document.createElement("canvas");
      // set global canvas instance
      tempCanvas = new fabric.Canvas(canvasElmt);
      tempCanvas.enableRetinaScaling = false;

      const originalImageUrl = await filesRequests.getOriginalImage(
        product.productFilesId
      );

      console.log("loading image");
      // load image
      fabric.Image.fromURL(
        originalImageUrl,
        async function (img) {
          tempCanvas.setDimensions({ width: img.width, height: img.height });

          tempCanvas.setBackgroundImage(
            img,
            tempCanvas.renderAll.bind(tempCanvas),
            {
              scaleX: 1,
              scaleY: 1,
            }
          );

          imageWidth = img.width;
          imageHeight = img.height;

          // call after background image established
          await _setObjectsAndScale(canvasObjects);
          await _addHighResolutionQRCode(QRText);
          // _checkForMovedBoxesAndReadjust(tempCanvas);

          window.tempCanvas = tempCanvas;

          // _testImg()
          // return;
          resolve(tempCanvas);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    } catch (err) {
      console.error("Error preparing canvas", err.message);
      reject(err.message);
    }
  });
};

export {
  getCanvas,
  initCanvas,
  getPosterbase64Image,
  createPDFAndDownloadV2,
  generateVideoAndGetURL,
  getSmallerCanvasBase64ImageForJPEG,
};
