import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import FontFaceObserver from "fontfaceobserver";
import { nanoid } from "nanoid";
// custom components
import ScalePercentage from "../editor/scalePercentage";
import BoxAlignment from "../editor/boxAlignment";
import FontManipulation from "../editor/fontManipulation";
import ChangeColor from "../editor/color";
import FieldTypes from "../editor/fieldTypes";
import AddedFields from "../editor/addedFields";
import RotateText from "../editor/rotateText";
import QrCodeEditor from "./qrCodeEditor";
import SongsList from "./songsList";
import DownloadCard from "./downloadCard";
// hooks
import { useLocation, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../hooks/useIsMounted";
// requests
import productRequests from "../../../../request/products";
import customerPurchasesRequests from "../../../../request/customerPurchases";
// fabric
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric";
import { objectMovingAutoAlign, removeAlignLines } from "../fieldAutoAlign";
import { undoRedoController2 } from "../undoRedo2";
import {
  duplicateCurrentObjectController,
  duplicateCurrentObject,
} from "../duplicateCurrentObject";
// utils
import { hasHebrewCharacters } from "../../../../utils/text";
import { showWarningToast } from "../../../../utils/warning";
import {
  assingObjectsToCanvas,
  forceRenderUpdate,
  setCanvasAtResoution,
  setCustomFontsWhenLoaded,
} from "../../../../utils/canvas2";

import availableFields from "../availableFields";
import "../style/ProductFieldsEditor.css";

// import locastionStateMock from './orderDataMock';

const defaultCanvasObjState = {
  fontSize: 24,
  fill: "#000000",
  textAlign: "center",
  fontWeight: "normal",
  text: "",
  horizontallyCentered: false,
  lineHeight: 1,
  charSpacing: 0,
  fontFamily: "arial",
};

const defaultEditorSize = { width: 800, height: 800 };

const defaultQRCodeWidth = 120;

// temporal fix, for some reason the fields are loading twice and bigger the second time
// so this global variable checks to don't repeat printing fields
let DO_NOT_PRINT_TWICE = false;

// const locationState = {
//     fullData: locastionStateMock.data
// }

const EditCustomerCardEditor = () => {
  const { editor, onReady } = useFabricJSEditor();
  const { state: locationState } = useLocation();
  const isMounted = useIsMounted();
  const firstRender = useRef(true);
  const [searchParams] = useSearchParams();
  const [productId, setProductId] = useState("");
  const [wpOrderData, setWpOrderData] = useState(null);

  const [variantForm, setVariantForm] = useState(defaultEditorSize);
  const [productData, setProductData] = useState({});
  const [productFields, setProductFields] = useState([]);
  const [activeCanvasObjectStyles, setActiveCanvasObjectStyles] = useState(
    defaultCanvasObjState
  );
  const [qrCodeText, setQrCodeText] = useState("");
  const [purchasedSongData, setPurchasedSongData] = useState(null);
  const [selectedSong, setSelectSong] = useState(null);
  const [, setIsAlignEnabled] = useState(false);
  const [, setAddedFields] = useState([]);

  useEffect(() => {
    return () => {
      DO_NOT_PRINT_TWICE = false;
    };
  }, []);

  // get product data (image and fields)
  useEffect(() => {
    async function getProductData() {
      if (Object.keys(productData).length === 0 && editor?.canvas) {
        editor.canvas.setDimensions({
          height: variantForm.height,
          width: variantForm.width,
        });

        let _productId = locationState?.fullData?.orderRequest?.productId;

        // console.log("##### product id por route???", _productId);
        // console.log("#####@@@@ search params are", searchParams);

        // get mongodb product id stored in woocomerce if we are accesing to this page directly from a url
        // instead from a previous page (orders list) with data already in route state
        if (!_productId) {
          const { order_id, order_item_id, cart_item_key } =
            Object.fromEntries(searchParams); // destructuring Map
          const _wooOrderData = await customerPurchasesRequests.getWooOrderItem(
            {
              wooOrderId: order_id,
              wooOrderItemId: order_item_id,
              wooCartItemKey: cart_item_key,
            }
          );

          _productId = _wooOrderData.customizer_product_id;
          setWpOrderData(_wooOrderData);
        }

        await setProductId(_productId);

        // get image data and updated name, price, etc but not fields
        await productRequests
          .getProductAsAdmin(_productId)
          .then((_productData) => {
            if (isMounted.current) {
              const { path } = _productData.filesId;
              setProductData(_productData);
              setBackgroundImageToEditor(
                path,
                getAndPrintProductFields(_productId)
              );
            }
          });

        localStorage.setItem("isAlignEnabled", "false");

        editor.canvas.on("before:selection:cleared", () => {
          removeAlignLines(editor.canvas);
          setActiveCanvasObjectStyles(defaultCanvasObjState);
        });
        editor.canvas.on("selection:created", () => {
          const activeObjects = editor.canvas.getActiveObjects();
          // reset active object left and top
          if (activeObjects.length >= 2) {
            editor.canvas.fire("group:created");
          }
        });
      }
    }

    getProductData()
      .then("product data fetched")
      .catch((err) => console.error("can't fetch product data", err));
  }, [editor]);

  // undo/redo and ctrl+x (duplicate), also select items with ctrl+click
  useEffect(() => {
    if (editor?.canvas && firstRender.current) {
      undoRedoController2({
        canvas: editor.canvas,
        callbackPerTxtObj: (txtObj) => addEventsToTxtObj(txtObj),
        onStateRecovered: (txtObjects) => setAddedFields(() => txtObjects),
      });

      duplicateCurrentObjectController({
        canvas: editor.canvas,
        addEventsCb: (txtObj) => addEventsToTxtObj(txtObj),
        onAddedCallback: (txtObjects) => setAddedFields(() => txtObjects),
      });

      editor.canvas.selectionKey = "ctrlKey";

      firstRender.current = false;
    }
  }, [editor, firstRender]);

  // delete when press DEL button
  useEffect(() => {
    function handleDeleteByBtn(ev) {
      if (ev.key === "Delete") {
        if (editor) {
          const activeObj = editor.canvas.getActiveObject();
          if (activeObj) {
            editor.canvas.remove(activeObj);
          }
        }
      }
    }

    window.addEventListener("keyup", handleDeleteByBtn);
    return () => window.removeEventListener("keyup", handleDeleteByBtn);
  }, [editor]);

  // remove page wrapper padding
  useLayoutEffect(() => {
    const pageWrapper = document.querySelector(".content");
    pageWrapper.classList.add("no-padding");

    return () => pageWrapper.classList.remove("no-padding");
  }, []);

  const setBackgroundImageToEditor = (imageUrl, callback) => {
    fabric.Image.fromURL(
      imageUrl,
      function (img) {
        // change canvas height relative to image height
        const scaleFactor = img.width / editor.canvas.width;
        const newCanvasHeight = img.height / scaleFactor;
        editor.canvas.setDimensions({
          width: editor.canvas.width,
          height: newCanvasHeight,
        });

        editor.canvas.setBackgroundImage(
          img,
          editor.canvas.renderAll.bind(editor.canvas),
          {
            scaleX: editor.canvas.width / img.width,
            scaleY: editor.canvas.height / img.height,
          }
        );

        setVariantForm({
          ...variantForm,
          height: newCanvasHeight,
          width: editor.canvas.getWidth(),
        });

        callback();
      },
      {
        crossOrigin: "Anonymous",
      }
    );
  };

  // get product fields from url search params or route state
  const getAndPrintProductFields = async (productId) => {
    console.log({ wpOrderData, DO_NOT_PRINT_TWICE });

    if (!productId || DO_NOT_PRINT_TWICE) return;

    // temporal fix
    DO_NOT_PRINT_TWICE = true;

    let canvasObjects;
    let canvasObjNew = locationState.fullData.orderRequest.customization_data;

    let parsedData =
      typeof canvasObjNew === "string"
        ? JSON.parse(canvasObjNew)
        : canvasObjNew;

    // by url search params
    if (searchParams.size > 0) {
      console.log("\nsearch params > 0");
      canvasObjects = wpOrderData.customization_data.canvasObjects;
      setQrCodeText(wpOrderData.customization_data.QRText);
      setPurchasedSongData(wpOrderData.customization_data.song);
    }
    // by route state
    else {
      canvasObjects = parsedData.canvasObjects;
      setQrCodeText(parsedData.QRText);
      setPurchasedSongData(parsedData.song);
    }
    // console.log(
    //   "locationState.fullData.customization_data.canvasObjects",
    //   canvasObjects
    // );
    // assing object to canvas and add events
    if (canvasObjects?.length && isMounted.current) {
      const { canvasWidth: previousCanvasWidth } = canvasObjects.find(
        (obj) => obj.canvasWidth
      );

      await assingObjectsToCanvas(editor.canvas, canvasObjects);
      setCanvasAtResoution(
        editor.canvas,
        defaultEditorSize.width,
        previousCanvasWidth,
        null
      );
      // add temporal ID, events and custom properties
      editor.canvas.getObjects().forEach((obj, idx) => {
        obj.tempId = nanoid(10);
        obj.indexOrder = obj.indexOrder || idx;
        setAddedFields((prev) => [...prev, obj]);
        addEventsToTxtObj(obj);
      });

      await setCustomFontsWhenLoaded(editor.canvas);
      setProductFields(canvasObjects);

      // force re render charSpacing and set border color
      for (const obj of editor.canvas.getObjects()) {
        if (obj.type === "textbox") {
          const { borderColor } = availableFields.find(
            (d) => d.label === obj.label
          );
          let spacing = obj.charSpacing;

          obj.charSpacing++;
          editor.canvas.renderAll();
          obj.charSpacing = spacing;
          obj.borderColor = borderColor;
          editor.canvas.renderAll();
        }
      }

      await forceRenderUpdate(editor.canvas, true, false, true);
    }
  };

  // control qr code position scaling
  const handleQRCodeScaling = (rectObj) => {
    if (rectObj.getScaledWidth() < defaultQRCodeWidth) {
      rectObj.set({ width: defaultQRCodeWidth, height: defaultQRCodeWidth });
      rectObj.scale(1, 1);
    }
  };

  // add events to single text object
  const addEventsToTxtObj = (txtObj) => {
    txtObj.on("selected", () => {
      handleActiveObjClick();
    });
    txtObj.on("moving", () => {
      objectMovingAutoAlign(txtObj, editor.canvas);
    });
    txtObj.on("moved", (ev) => {
      console.log("object was moved", txtObj.text);
      handleObjectMoved(ev);
    });
    txtObj.on("changed", () => {
      handleDetectTextDirection(txtObj);
      // editor.canvas.fire('text:change');
    });

    if (txtObj.type === "rect") {
      txtObj.setControlsVisibility({
        ml: false,
        mb: false,
        mr: false,
        mt: false,
      });
      txtObj.on("scaling", () => {
        handleQRCodeScaling(txtObj);
      });
    } else {
      txtObj.setControlsVisibility({
        mb: false,
        mt: false,
        tl: false,
        tr: false,
        bl: false,
        br: false,
        mtr: false,
      });
    }
  };

  // set active obj values to form
  const handleActiveObjClick = () => {
    const activeObj = editor.canvas.getActiveObject();
    setActiveCanvasObjectStyles({
      ...activeObj,
    });
  };

  // set to default position if object is outside canvas limit
  const handleObjectMoved = (ev) => {
    const textObj = ev.target;
    const { top, left, width } = textObj;
    const canvasWidth = editor.canvas.getWidth();
    const canvasHeight = editor.canvas.getHeight();
    const leftRange = -width + 50;
    const rightRange = canvasWidth - 50;

    if (
      top < 0 ||
      left < leftRange ||
      left > rightRange ||
      top > canvasHeight ||
      left > canvasWidth
    ) {
      textObj.top = 200;
      textObj.left = 100;
      textObj.setCoords();
      editor.canvas.renderAll();
    }

    removeAlignLines(editor.canvas);
  };

  const handleDetectTextDirection = (txtObj) => {
    // has hebrew characters
    txtObj.direction = hasHebrewCharacters(txtObj.text) ? "rtl" : "ltr";
    editor.canvas.renderAll();
    setActiveCanvasObjectStyles((prev) => ({ ...prev, ...txtObj }));
  };

  const onFieldTypeClick = (fieldLabel) => {
    const getCanvasTextObj = () =>
      editor.canvas.getObjects().find((x) => x.text && x.text === fieldLabel);
    // if is the same text don't add new field
    if (getCanvasTextObj()) return;

    const { borderColor } = availableFields.find((d) => d.label === fieldLabel);

    let txtObject;

    if (fieldLabel === "QR Code") {
      txtObject = new fabric.Rect({
        textAlign: "center",
        fontFamily: "arial",
        left: 50,
        top: 50,
        width: defaultQRCodeWidth,
        height: defaultQRCodeWidth,
      });
    } else {
      txtObject = new fabric.Textbox(fieldLabel, {
        fill: "#000000",
        textAlign: "center",
        lockScalingY: true,
        fontFamily: "arial",
        left: 50,
        top: 50,
        borderColor,
      });
      txtObject.set({ text: fieldLabel });
    }

    // set default values
    const defaultValues = {
      ...defaultCanvasObjState,
      text: fieldLabel,
      originalText: fieldLabel,
      label: fieldLabel,
      autocompleteLabel: fieldLabel,
      productId,
    };
    for (let [key, value] of Object.entries(defaultValues)) {
      txtObject[key] = value;
    }
    // set index order (for using in frontend input list order)
    txtObject.indexOrder = editor.canvas.getObjects().length;

    editor.canvas.add(txtObject);

    editor.canvas.setActiveObject(txtObject);
    setActiveCanvasObjectStyles(txtObject);

    // add temporal id
    txtObject.tempId = nanoid(10);
    setAddedFields((prev) => [...prev, txtObject]);

    // add some events
    addEventsToTxtObj(txtObject);

    // force rect color change
    if (fieldLabel === "QR Code") {
      editor.canvas.getActiveObject().fill = borderColor;
    }

    // render
    editor.canvas.renderAll();
  };

  const handleAutoAlign = (ev) => {
    setIsAlignEnabled(ev.target.checked);
    localStorage.setItem("isAlignEnabled", ev.target.checked);
  };

  const handleDeleteAddedField = (tempId) => {
    const objToDelete = editor.canvas
      .getObjects()
      .find((obj) => obj.tempId === tempId);
    if (!objToDelete) return;
    editor.canvas.remove(objToDelete);
    editor.canvas.renderAll();
    setActiveCanvasObjectStyles((_) => ({ ...defaultCanvasObjState }));
  };

  const handleDuplicateField = (tempId) => {
    const txtObj = editor.canvas
      .getObjects()
      .find((obj) => obj.tempId === tempId);
    if (!txtObj) return;

    duplicateCurrentObject({
      canvas: editor.canvas,
      addEventsCb: (txtObj) => addEventsToTxtObj(txtObj),
      onAddedCallback: (txtObjects) => setAddedFields(() => txtObjects),
      objToDuplicate: txtObj,
    });
  };

  const toggleViewField = (tempId) => {
    const txtObj = editor.canvas
      .getObjects()
      .find((obj) => obj.tempId === tempId);
    if (!txtObj) return;

    txtObj.visible = !txtObj.visible;
    editor.canvas.setActiveObject(txtObj);
    editor.canvas.renderAll();
    setActiveCanvasObjectStyles((prev) => ({ ...prev, ...txtObj }));
  };

  const onShowSelected = (tempId) => {
    const txtObj = editor.canvas
      .getObjects()
      .find((obj) => obj.tempId === tempId);
    if (!txtObj) return;

    editor.canvas.setActiveObject(txtObj);
    editor.canvas.renderAll();
    setActiveCanvasObjectStyles((prev) => ({ ...prev, ...txtObj }));
  };

  const onLabelChange = (tempId, newLabel) => {
    const txtObj = editor.canvas
      .getObjects()
      .find((obj) => obj.tempId === tempId);
    if (!txtObj) return;

    const { borderColor } = availableFields.find((f) => f.label === newLabel);

    txtObj.label = newLabel;
    txtObj.autocompleteLabel = newLabel;
    txtObj.borderColor = borderColor;
    onShowSelected();
  };

  const handleBeforeAfterText = (isBefore, text) => {
    const activeObjs = editor.canvas.getActiveObjects();
    if (activeObjs.length) {
      for (const activeObj of activeObjs) {
        if (isBefore) activeObj.beforeText = text;
        else activeObj.afterText = text;

        const { beforeText, afterText, originalText } = activeObj;

        activeObj.text = `${beforeText || ""} ${originalText || ""} ${
          afterText || ""
        }`.trim();
        setActiveCanvasObjectStyles({
          ...activeObj,
        });
      }
      editor.canvas.renderAll();
    } else {
      showWarningToast();
    }
  };

  const handleStyleChange = (ev) => {
    const { name, value } = ev.target;
    const activeObjs = editor.canvas.getActiveObjects();

    if (activeObjs.length) {
      for (const activeObj of activeObjs) {
        if (activeObj.type === "rect") continue;

        let oldWidth = activeObj.width;
        let oldFontSize = activeObj.fontSize;
        activeObj[name] = value;

        // force text color update
        if (name === "fill") {
          activeObj.fontSize = oldFontSize + 1;
          editor.canvas.renderAll();
          // set font size and width to original value
          activeObj.fontSize = oldFontSize;
          activeObj.width = oldWidth;
        }

        // delete font cache if the font weight is changed
        if (name === "fontWeight") {
          changeFontFamily(activeObj.fontFamily, value);
        }

        // change text direction
        if (name === "text") {
          const { beforeText, afterText } = activeObj;
          activeObj.originalText = value;
          activeObj.text = `${beforeText || ""} ${value} ${
            afterText || ""
          }`.trim();
          handleDetectTextDirection(activeObj);
        }

        // uppercase
        if (name === "uppercased") {
          activeObj.text = value
            ? activeObj.text.toUpperCase()
            : activeObj.text.toLowerCase();
        }

        setActiveCanvasObjectStyles({
          ...activeObj,
        });
      }

      editor.canvas.fire(`${name}:change`); // useful for undo/redo feature
      editor.canvas.renderAll();
    } else {
      showWarningToast();
    }
  };

  const handleRotate = (ev) => {
    const { value } = ev.target;
    const activeObj = editor.canvas.getActiveObject();

    if (!activeObj) {
      showWarningToast();
      return;
    }

    activeObj.rotate(Number(value));

    editor.canvas.fire("object:rotate"); // for undo/redo feature
    editor.canvas.renderAll();
    setActiveCanvasObjectStyles({ ...activeObj });
  };

  const changeFontFamily = (
    fontName,
    fontWeight = "normal",
    style = "normal"
  ) => {
    const activeObjs = editor.canvas.getActiveObjects();
    const defaultFonts = ["arial", "verdana", "consolas", "fantasy"];

    if (activeObjs.length) {
      for (const activeObj of activeObjs) {
        if (defaultFonts.includes(fontName)) {
          activeObj.fontFamily = fontName;
          editor.canvas.renderAll();

          setActiveCanvasObjectStyles((prevState) => ({
            ...prevState,
            fontFamily: fontName,
            fontWeight: fontWeight,
            fontStyle: style,
          }));
          continue;
        }
        // load custom fonts
        let fontObserver = new FontFaceObserver(fontName, {
          weight: fontWeight,
          style,
        });

        fontObserver
          .load(null, 1000)
          .then(() => {
            // force font update
            activeObj.fontFamily = fontName;
            activeObj.fontSize++;
            editor.canvas.renderAll();
            activeObj.fontSize--;
            activeObj.fontWeight = fontWeight;
            activeObj.fontStyle = style;

            if (activeObj.charSpacing === 0) activeObj.charSpacing = 1;

            setActiveCanvasObjectStyles((prevState) => ({
              ...prevState,
              ...editor.canvas.getActiveObject(),
            }));

            console.log(`${fontName} ${fontWeight} ${style} loaded`);
          })
          .catch((err) => {
            console.error(
              `can't load the font ${fontName} ${fontWeight} ${style}`,
              err
            );
            activeObj.fontFamily = fontName;
          })
          .finally(() => {
            setActiveCanvasObjectStyles((prevState) => ({
              ...prevState,
              fontWeight,
              fontFamily: fontName,
              fontStyle: style,
            }));
            window.fabric.util.clearFabricFontCache(fontName);
            editor.canvas.renderAll();
          });
      }
      editor.canvas.fire("fontFamily:change"); // useful for undo/redo feature
    } else {
      showWarningToast();
    }
  };

  window.editor = editor; // just for debug

  return (
    <React.Fragment>
      <Helmet title="Product editor" />
      {productData.name && (
        <h2 className="h2 mb-2 text-center">{productData.name}</h2>
      )}
      <Container fluid className="p-0">
        <div className="editor-container-flex">
          {/* left */}
          <div className="left-editor">
            <FieldTypes onClick={onFieldTypeClick} isEditPurchasePage />

            <AddedFields
              canvasObjects={editor?.canvas.getObjects()}
              setActiveCanvasObjectStyles={setActiveCanvasObjectStyles}
              activeObj={activeCanvasObjectStyles}
              onDelete={handleDeleteAddedField}
              onDuplicate={handleDuplicateField}
              onViewToggle={toggleViewField}
              onShowSelected={onShowSelected}
              onLabelChange={onLabelChange}
              isEditPurchasePage
            />
          </div>

          {/* center */}
          <div className="canvas-editor-container center-editor">
            <FabricJSCanvas className="canvas mt-3 mb-3" onReady={onReady} />
          </div>

          {/* right */}
          <div className="right-editor">
            <ScalePercentage />
            <BoxAlignment
              canvas={editor?.canvas}
              setActiveCanvasObjectStyles={setActiveCanvasObjectStyles}
              handleAutoAlign={handleAutoAlign}
            />
            <FontManipulation
              changeFontFamily={changeFontFamily}
              activeCanvasObject={activeCanvasObjectStyles}
              onChange={handleStyleChange}
              handleBeforeAfterText={handleBeforeAfterText}
            />
            <ChangeColor
              onChange={handleStyleChange}
              activeObject={activeCanvasObjectStyles}
              productFields={productFields}
            />
            <RotateText
              onChange={handleRotate}
              activeCanvasObject={activeCanvasObjectStyles}
            />

            <QrCodeEditor
              canvas={editor?.canvas}
              qrText={qrCodeText}
              setQRText={setQrCodeText}
            />

            <SongsList
              productId={productId || productData._id}
              purchasedSongData={purchasedSongData}
              onSelectSong={setSelectSong}
            />

            <DownloadCard
              canvas={editor?.canvas}
              qrText={qrCodeText}
              songData={selectedSong}
              productData={productData}
            />

            {/* <AutofillableField
                            activeObjectStyles={activeCanvasObjectStyles}
                            canvas={editor?.canvas}
                            setActiveCanvasObjectStyles={setActiveCanvasObjectStyles}
                        /> */}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default EditCustomerCardEditor;
