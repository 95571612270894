import axios from 'axios';
import { API_BASE_URL } from '../config';

const orderRequests = {};

orderRequests.getorder = async function () {
    const url = `${API_BASE_URL}/orders/allgetOrder`;

    try {
        const response = await axios.get(url);
        // console.log(response.data.data, "response data");
        return response.data.data;

    } catch (error) {
        console.error("Error fetching orders:", error.message);
        return [];
    }
};

export default orderRequests;