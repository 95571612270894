import { Modal, Button, Form } from "react-bootstrap";

import coupons from "../../../request/coupons";
import Swal from "sweetalert2";

export const EditCouponModal = ({
  show,
  handleClose,
  formData,
  onFormChange,
  couponId,
}) => {
  console.log(formData, "formDataformDataformDataformData");
  const handleChange = (e) => {
    const { name, value } = e.target;
    onFormChange((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await coupons.updateCoupon(couponId, formData);
      if (!response.error) {
        Swal.fire({
          title: "Success",
          text: "Coupon updated successfully.",
          icon: "success",
        });
        handleClose();
      } else {
        throw new Error(response.message || "Failed to update coupon.");
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message || "Failed to update coupon.",
        icon: "error",
      });
    }
  };

  function formatDateToISO(date) {
    const mdate = new Date(date);
    const year = mdate.getFullYear();
    const month = String(mdate.getMonth() + 1).padStart(2, "0");
    const day = String(mdate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Coupon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Coupon Code</Form.Label>

            <Form.Control
              type="text"
              name="code"
              value={formData.code || ""}
              onChange={handleChange}
              placeholder="Enter coupon code"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Discount</Form.Label>
            <Form.Control
              type="number"
              name="discount"
              value={formData.discount || ""}
              onChange={handleChange}
              placeholder="Enter discount"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={
                formData.startDate ? formatDateToISO(formData.startDate) : ""
              }
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={formData.endDate ? formatDateToISO(formData.endDate) : ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Button type="submit" size="lg" variant="primary">
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
